import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useHttpClient } from "../Hooks/HttpHook";
import { PAGE_SIZE } from "../Shared/globalVar";
import addQueryStringsToUrl from "../Utils/addQueryStringsToUrl";

interface IStatus {
  name: string;
  items?: Array<any>;
  needsLoading: boolean;
  count: number;
}

export default class ApiService {

  protected static async sendRequest({
    httpClient,
    url,
    method = "get",
    body = null,
    headers = {},
  }: {
    httpClient: any,
    url: string;
    method: string;
    body?: any;
    headers?: any;
  }) {
    try {
      const response = await httpClient.sendRequest({
        url: url,
        method: method,
        body: body,
        headers: { ...headers },
      });
      return response;
    } catch (error) {
      console.error("Request error:", error);
      throw error;
    }
  }

  protected static calculatePagination(
    dataGridApiRef: React.MutableRefObject<GridApiCommunity>,
    items: any[]
  ): [number, any[]] {
    const currentPage =
      dataGridApiRef.current.state.pagination.paginationModel.page;

    const skipAmount = currentPage * PAGE_SIZE;

    const deleteItemsAfter = currentPage * PAGE_SIZE;

    const existingItems = items.slice(0, deleteItemsAfter);

    return [skipAmount, existingItems];
  }

  public static async getItems({
    status,
    statuses,
    getCount,
    items,
    setItems,
    setCount,
    httpClient,
    dataGridApiRef,
    requestUrl,
    requestMethod,
    responseField,
    requestBody = {},
    queryStrings = [],
    headers,
  }: {
    status?: number;
    statuses?: IStatus[];
    getCount: boolean;
    items: any[];
    setItems: any;
    setCount: any;
    httpClient: any;
    dataGridApiRef: React.MutableRefObject<GridApiCommunity>;
    requestUrl: string;
    requestMethod: string;
    responseField: string;
    requestBody?: object;
    queryStrings?: string[];
    headers?: object;
  }) {
    try {
      const [skipAmount, existingItems] = this.calculatePagination(
        dataGridApiRef,
        items
      );

      const allQueryStrings = [
        ...queryStrings,
        `skip=${skipAmount}`,
        `descending=true`,
      ];

      const url = addQueryStringsToUrl(requestUrl, allQueryStrings);

      let itemsStatus = null;

      console.log(`apiservice - status`)
      console.log(status);

      console.log(`apiservice - statuses`)
      console.log(statuses)

      if (status !== undefined && status !== null && statuses) {
        statuses[status].name === "All"
          ? (itemsStatus = null)
          : (itemsStatus = statuses[status].name);
      }

      const response = await httpClient.sendRequest({
        url: url,
        method: requestMethod,
        body: {
          getCount: getCount,
          ...requestBody,
          status: itemsStatus,
        },
        headers: headers,
      });

      console.log(`ApiService - getItems - response`);
      console.log(response);

      if (response[responseField]) {
        setItems([...existingItems, ...response[responseField]]);
      }

      if (getCount) {
        setCount(response.count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public static async updateItem({
    httpClient,
    requestUrl,
    requestMethod,
    requestBody = {},
    queryStrings = [],
    requestHeaders,
  }: {
    httpClient: any;
    requestUrl: string;
    requestMethod: string;
    requestBody?: object;
    queryStrings?: string[];
    requestHeaders?: object;
  }) {
    try {
      const allQueryStrings = [...queryStrings];

      const url = addQueryStringsToUrl(requestUrl, allQueryStrings);

      const response = await httpClient.sendRequest({
        url: url,
        method: requestMethod,
        body: {
          ...requestBody,
        },
        headers: {
          ...requestHeaders,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
