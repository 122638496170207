import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import {
  Box,
  Modal,
} from "@mui/material";

interface PopupBaseProps {
  open: any;
  setOpen: any;
  children?: React.ReactNode;
}

const PopupBase: React.FunctionComponent<PopupBaseProps> = (props) => {
  const handleClose = () => props.setOpen(false);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
        {props.children}
        </Box>
      </Modal>
    </div>
  );
};

export default PopupBase;
