import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";

import TableList from "../../Components/Table/Table";
import { Button, MenuItem, Select } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import CustomerList from "../CustomerList/CustomerList";
import OrderTransactionList from "../OrderTransactionList/OrderTransactionList";
import { AdminStatusEnum, OrderListType } from "../../Data/Enums";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import formatDate from "../../Utils/formatDate";
import SearchBar from "../../Components/SearchBar/SearchBar";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { PAGE_SIZE } from "../../Shared/globalVar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useCustomHttpClient } from "../../Hooks/useCustomHttpClient";
import * as XLSX from "xlsx";
import usePaginationUtil from "../../Hooks/usePaginationUtil";
import useSearchBar from "../../Hooks/useSearchBar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import OrderService from "../../Services/OrderService";

interface OrderListProps {
  canChangeStatus: boolean;
  vendorTransactionId?: string | null;
  transactionId?: string | null;
  customerId?: string | null;
  returnToOriginalComponent?: () => void;
}

const OrderList: React.FunctionComponent<OrderListProps> = (
  props: OrderListProps
) => {

  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();

  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById
  } = useSearchBar({
    getByIdRequestUrl: "/owner/orders",
    getByIdRequestMethod: "post",
    responseField: "data"
  });

  useEffect(() => {
    getOrders(true);
  }, [props.canChangeStatus, status]);


  let orderAdminStatuses = [
    {
      name: "All",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Pending,
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Received,
      // name: "Received",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Packed,
      // name: "Packed",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Shipped,
      // name: "Shipped",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Returned,
      orders: [],
      needsLoading: true,
      count: 0,
    },
  ];

  let columns: GridColDef[] = [
    {
      field: "productImage",
      headerName: "Product Image",
      width: 150,
      renderCell: (params) =>
        params.row.productImage && (
          <img
            width={100}
            height={120}
            src={params.row.productImage}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.productImage);
            }}
          />
        ),
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 230,
      editable: true,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 230,
      editable: true,
    },
    {
      field: "_id",
      headerName: "Order Item ID",
      width: 230,
      editable: true,
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 120,
      editable: true,
    },
    {
      field: "number",
      headerName: "Number",
      width: 70,
      editable: true,
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 100,
      editable: true,
    },
    {
      field: "topLevelCategoryName",
      headerName: "Top Level Category",
      width: 130,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 70,
      editable: false,
    },
    {
      field: "size",
      headerName: "Size",
      width: 20,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 70,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor ID",
      width: 225,
      editable: true,
    },
    {
      field: "customerUserId",
      headerName: "Customer ID",
      width: 230,
      editable: true,
    },
    {
      field: "airwayBill",
      headerName: "Airway Bill",
      width: 120,
      editable: true,
    },
    {
      field: "color",
      headerName: "Color",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: `${params.value}`,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2E2E2E",
            fontWeight: "bold",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "delivered",
      headerName: "Delivered",
      width: 80,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  columns = props.canChangeStatus
  // if inventory, add column with updatable admin status
    ? [
        ...columns,
        {
          field: "adminStatus",
          headerName: "Admin Status",
          width: 150,
          editable: true,
          renderCell: (params) => (
            <Select
              value={params.row.adminStatus}
              onChange={async (e) => {
                console.log(params);
                await updateAdminStatus(
                  params.row._id,
                  params.row.orderId,
                  e.target.value
                );
                await getOrders(true);
              }}
              style={{
                backgroundColor:
                  params.row.adminStatus === AdminStatusEnum.Shipped
                    ? "rgba(77, 74, 191, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Packed
                    ? "rgba(253, 177, 102, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Received
                    ? "rgba(204, 255, 229, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Returned
                    ? "rgba(255, 200, 195, 0.5)"
                    : "",
              }}
            >
              {orderAdminStatuses
                .filter((status) => status.name !== "All")
                .map((status) => (
                  <MenuItem value={status.name}>{status.name}</MenuItem>
                ))}
            </Select>
          ),
        },
      ]
      // if orders, add column with non-updatable admin status
    : [ 
      
        ...columns,
        {
          field: "adminStatus",
          headerName: "Admin Status",
          width: 100,
          editable: true,
        },
      ];

  const getOrders = async (getCount: boolean, currentPageUpdated?: boolean) => {

      let url = `/owner/orders`;

      let queryStrings: string[] = []

      if (props.customerId) {
      
        url = `/owner/orders/getbycustomer`
        queryStrings.push(`customerId=${props.customerId}`)

      } else if (props.transactionId) {
      
        url = `/owner/orders/getbytransaction`;
        queryStrings.push(`orderTransactionId=${props.transactionId}`);


      } else if (props.vendorTransactionId) {
        
        url = `/owner/orders`;
        queryStrings.push(`vendorTransactionId=${props.vendorTransactionId}`);
      }

      await OrderService.getItems({
        status: status,
        statuses: orderAdminStatuses,
        getCount: getCount,
        setCount: setCount,
        items: items,
        setItems: setItems,
        httpClient: httpClient,
        dataGridApiRef: dataGridApiRef,
        requestUrl: url,
        queryStrings: queryStrings,
        requestMethod: "post",
        responseField: "data"
      });
  
  };

  const updateAdminStatus = async (
    orderItemId: string,
    orderId: string,
    adminStatus: string
  ) => {

    await OrderService.updateItem({
      httpClient: httpClient,
      requestUrl: `/owner/orders/adminStatus`,
      requestMethod: "put",
      requestBody: {
        orderItemId: orderItemId,
        orderId: orderId,
        adminStatus: adminStatus
      }
    });

  };

  return (
    // show the main Orders page

    <div className="page">
      <SnackbarErrorClient client={httpClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <div
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {props.returnToOriginalComponent && (
          // if Orders has been rendered from other component (e.g. Customers), display arrow to return to original component
          <FaArrowLeft onClick={props.returnToOriginalComponent} />
        )}

        <p style={{ marginBottom: 0 }} className="pageTitle">
          {props.canChangeStatus ? "Inventory" : "Orders"}
        </p>
      </div>

      {props.canChangeStatus ? (
        // if displaying Inventory, show action buttons
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {orderAdminStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCount(0);
                setItems([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      ) : (
        // otherwise, do not display action buttons
        <p></p>
      )}

      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await OrderService.exportToCSV({httpClient: httpClient});
          }}
        >
          Export to CSV
        </Button>
      </div>

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await OrderService.exportToExcel({httpClient: httpClient});
          }}
        >
          Export to Excel
        </Button>
      </div>

      <TableList
        // key={props.canChangeStatus.toString()}
        rows={searchBarResults.length > 0 ? searchBarResults : items}
        isLoading={
          httpClient.isLoading || httpClient.isLoading
        }
        columns={columns}
        rowsCount={count}
        getItems={(p) => getOrders(false)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </div>
  );
};

export default OrderList;
