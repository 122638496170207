const addQueryStringsToUrl = (baseUrl: string, queryStrings: string[]): string => {
  for (let i = 0; i < queryStrings.length; i++) {
    if (i === 0) {
      baseUrl += "?";
    } else {
      baseUrl += "&";
    }
    // i === 0 ? (baseUrl += "?") : (baseUrl += "&");
    baseUrl += queryStrings[i];
  }
  return baseUrl;
};

export default addQueryStringsToUrl;
