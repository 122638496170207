import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductList from "./Pages/ProductList/ProductList";
import SideBar from "./Components/SideBar/SideBar";
import Header from "./Components/Header/Header";
import Login from "./Pages/Auth/Login/Login";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthProvider } from "./Context/AuthProvider";
import RequireAuth from "./Components/RequireAuth";
import Layout from "./Pages/Layout";
import Unauthorized from "./Pages/Auth/Unauthorized/Unauthorized";
import PersistLogin from "./Components/PersistLogin";
import { ImageNotSupported } from "@mui/icons-material";
import ImageUpload from "./Components/SingleImageUpload/SingleImageUpload";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4D4ABF",
    },
    secondary: {
      main: "#000000",
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {/* <Route element={<RequireAuth allowedRoles={['superAdmin']} />}> */}
            <Route element={<PersistLogin />}>
              <Route path="/*" element={<Layout />} />
          </Route>

            {/* </Route> */}
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
