import { useState } from "react";
import { useHttpClient } from "./HttpHook";

const usePopupManager = (): [any, React.Dispatch<React.SetStateAction<boolean>>] => {

    const [open, setOpen] = useState(false);
    const httpClient = useHttpClient();

    return [
        open,
        setOpen
    ];
};

export default usePopupManager;