import react, { useEffect, useState } from "react";
import PopupBase from "../PopupBase/PopupBase";
import { useHttpClient } from "../../Hooks/HttpHook";
import MultipleImageUpload from "../MultipleImageUpload/MultipleImageUpload";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import useMultipleImageUploadManager from "../../Hooks/useMultipleImageUploadManager";
import imageCompression from "browser-image-compression";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import {
  ConnectingAirportsOutlined,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PageLoader from "../PageLoader";

interface MultipleImageUploadPopupProps {
  open: any;
  setOpen: any;
  item: any;
  getItems: Function;
  uploadImagesRequestUrl: string;
  uploadImagesRequestMethod: string;
  getExistingImagesRequestUrl?: string;
  getExistingImagesRequestMethod?: string;
  deleteImageRequestUrl?: string;
  deleteImageRequestMethod?: string;
}

const MultipleImageUploadPopup: React.FunctionComponent<
  MultipleImageUploadPopupProps
> = (props) => {
  
  let resetCropState = () => {};

  const { 
    imagesToUpload, 
    setImagesToUpload,
    existingImages,
    setExistingImages,
    aspectRatio,
    setAspectRatio,
    httpClient,
    error,
    setError,
    imageSrcs,
    setImageSrcs,
    readFileAsDataURL,
    getExistingImages,
    refreshData,
    handleDeleteImage
  } = useMultipleImageUploadManager({
    getItems: props.getItems,
    uploadImagesRequestUrl: props.uploadImagesRequestUrl,
    uploadImagesRequestMethod: props.uploadImagesRequestMethod,
    getExistingImagesRequestUrl: props.getExistingImagesRequestUrl,
    getExistingImagesRequestMethod: props.getExistingImagesRequestMethod,
    deleteImageRequestUrl: props.deleteImageRequestUrl,
    deleteImageRequestMethod: props.deleteImageRequestMethod
  });

  useEffect(() => {
    if (props.open) {
      setError("");
      setImagesToUpload([]);
      getExistingImages(props.item?.id);
    }
  }, [props.open]);

  useEffect(() => {
    setImagesToUpload([]);
    setImageSrcs([]);

    return () => {
      setExistingImages([]);
      setImagesToUpload([]);
      setImageSrcs([]);
    };
  }, []);

  // useEffect(() => {
  //   console.log(`useImageUploadManager`);
  //   console.log(`\timages`);
  //   console.log(images);
  //   console.log(imageSrcs);
  // });


  const onSubmit = async () => {
    try {
      const formData = new FormData();

      if (Array.isArray(imagesToUpload)) {
        console.log(`MultipleImageUploadPopup - images is an array`);
        console.log(imagesToUpload);
        imagesToUpload.forEach((image: any) => {
          formData.append("images", image);
        });
      } else {
        // formData.append("images", []);
        console.log("images is not an array");
        console.log(imagesToUpload);
      }

      formData.append("id", props.item?.id);

      console.log(`MultipleImageUploadPopup - formData`);
      formData.forEach((value, key) => {
        console.log(`\t${key}: ${value}`);
      });

      const response = await httpClient.sendRequest({
        url: props.uploadImagesRequestUrl,
        method: props.uploadImagesRequestMethod,
        body: formData,
        // body: {
        // images: images
        // }
      });

      console.log(response);

      await getExistingImages(props.item?.id);
      setImagesToUpload([]);
      setImageSrcs([]);

      props.getItems();

      resetCropState();
      
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PopupBase open={props.open} setOpen={props.setOpen}>
      <SnackbarErrorClient client={httpClient} />
      <PageLoader loading={httpClient.isLoading} />

      <h2>Existing Images</h2>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          height: "60%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {existingImages.map((imageSrc, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                src={imageSrc}
                alt={`image-${index}`}
                style={{
                  maxWidth: "200px",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
              />
              <IconButton
                onClick={() => handleDeleteImage(imageSrc, props.item?.id)}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "white",
                  backgroundColor: "#4d4abf",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </DialogContent>

      <h2>Add New Images</h2>

      <Box display="flex" justifyContent="center" marginBottom="10px">
        {/* <Button
          variant={aspectRatio === 1 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(1)}
          style={{ marginRight: "10px" }}
        >
          1:1
        </Button> */}
        {/* <Button
          variant={aspectRatio === 16 / 9 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(16 / 9)}
         style={{ marginRight: "10px" }}
        >
          16:9
        </Button> */}
        {/* <Button
          variant={aspectRatio === 4 / 3 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(4 / 3)}
        >
          4:3
        </Button> */}
      </Box>

      <MultipleImageUpload
        title=""
        images={imagesToUpload}
        setImages={setImagesToUpload}
        aspectRatio={aspectRatio}
        resetHandler={(resetFunc: () => void) => { resetCropState = resetFunc; }}
      />

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "15px" }}
        onClick={onSubmit}
      >
        Submit
      </Button>
      {/* <button onClick={onSubmit}>Submit</button> */}
    </PopupBase>
  );
};

export default MultipleImageUploadPopup;
