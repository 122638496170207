import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Box, Modal } from "@mui/material";
import PageLoader from "../PageLoader";
import CustomButton from "../CustomButton/CustomButton";

interface DeletePopupProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFunction: Function;
  deleteHttpClient: any;
}

const DeletePopup: React.FunctionComponent<DeletePopupProps> = (
  props
) => {
  const [error, setError] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {}, []);

  const onSubmit = async () => {
    try {
      await props.deleteFunction();
    }

    catch (error) {
      console.log(`Delete popup: error deleting item: ${error}`);
    }
  };

  return (
    <div>
      <PageLoader loading={props.deleteHttpClient.isLoading} />
      <SnackbarErrorClient client={props.deleteHttpClient} />
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">Delete</p>
          <form className="reviewProduct" action="">
            Are you sure you want to delete?
            <CustomButton
              text={"Delete"}
              onClick={onSubmit}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default DeletePopup;