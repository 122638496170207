import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableList from "../../Components/Table/Table";

import { MenuItem, Select, Snackbar } from "@mui/material";
import OrderList from "../OrderList/OrderList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useSearchBar from "../../Hooks/useSearchBar";

interface OrderTransactionListProps {}

const OrderTransactionList: React.FunctionComponent<
  OrderTransactionListProps
> = (props: OrderTransactionListProps) => {
  const [orderTransactions, setOrderTransactions] = useState([]);
  const getOrderTransactionsClient = useHttpClient();
  const [transactionId, setTransactionId] = useState(null);
  const [displayOrdersByOrderTransaction, setDisplayOrdersByOrderTransaction] =
    useState(false);

  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById,
  } = useSearchBar({
    getByIdRequestUrl: "/owner/ordertransaction",
    getByIdRequestMethod: "get"
  });

  useEffect(() => {
    getOrderTransactions();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "_id",
      headerName: "Order Transaction ID",
      width: 250,
      editable: false,
    },
    {
      field: "customerUser",
      headerName: "Customer ID",
      width: 250,
      editable: false,
    },
    // {
    //   field: "statusHistory",
    //   headerName: "Status History",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "currentStatus",
      headerName: "Current Status",
      width: 120,
      editable: false,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      editable: false,
    },
    {
      field: "subtotalAmount",
      headerName: "Subtotal Amount",
      width: 150,
      editable: false,
    },
    {
      field: "deliveryFees",
      headerName: "Delivery Fees",
      width: 150,
      editable: false,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      editable: false,
    },
    {
      field: "orderItems",
      headerName: "View Order Items",
      width: 150,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            console.log(params);
            setTransactionId(params.row._id);
            setDisplayOrdersByOrderTransaction(true);
          }}
        />
      ),
    },
  ];

  const getOrderTransactions = async () => {
    try {
      const response = await getOrderTransactionsClient.sendRequest({
        url: "/owner/ordertransaction",
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      console.log(response);
      if (response) {
        setOrderTransactions(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getOrderTransactionsClient.error);
    }
  };

  return displayOrdersByOrderTransaction ? (
    // show the orders page filtered by order transaction

    <OrderList
      canChangeStatus={false}
      transactionId={transactionId}
      returnToOriginalComponent={() => {
        setDisplayOrdersByOrderTransaction(false);
      }}
    />
  ) : (
    // otherwise, show the main OrderTransactions page

    <div className="page">
      <SnackbarErrorClient client={getOrderTransactionsClient} />

      <div className="pageUpper">
        <p className="pageTitle">Order Transactions</p>
      </div>

      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />

      <TableList
        rows={
          searchBarResults.length > 0 ? searchBarResults : orderTransactions
        }
        isLoading={getOrderTransactionsClient.isLoading}
        columns={columns}
        showRowNumbersNonPaginated
        rowNumbersDescending
      />
    </div>
  );
};

export default OrderTransactionList;
