import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../Shared/useDebounceEffect";
import { canvasPreview } from "../../Shared/canvasPreview";

interface MultipleImageUploadProps {
  setImages: any;
  title: string;
  images?: any[];
  aspectRatio?: number;
  resetHandler: Function;
}

interface CropState {
  crop?: any;
  completedCrop?: PixelCrop;
  scale: number;
  rotate: number;
}

const MultipleImageUpload: React.FunctionComponent<MultipleImageUploadProps> = (
  props
) => {
  ////////////////////////////////////////////////
  // Define State Variables
  ////////////////////////////////////////////////

  const [imgSrcs, setImgSrcs] = useState<string[]>([]);
  const [propsImgs, setPropsImgs] = useState<any[]>([]);
  const previewCanvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const imgRefs = useRef<(HTMLImageElement | null)[]>([]);
  const [aspect, setAspect] = useState<number>(1);

  // const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  // const previewCanvasRefs = useRef<HTMLCanvasElement[]>([]);
  // const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  // const blobUrlRef = useRef("");
  // const [scale, setScale] = useState(1);
  // const [rotate, setRotate] = useState(0);
  // const [crops, setCrops] = useState<Crop[]>();
  // const [completedCrops, setCompletedCrops] = useState<PixelCrop[]>();

  // const [scales, setScales] = useState<number[]>([]);
  // const [rotates, setRotates] = useState<number[]>([]);

  // const [crop, setCrop] = useState<Crop>();
  // const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  // const [imgs, setImgs] = useState<any[]>([]);
  // const [images, setImages] = useState<any[]>([]);
  // const imgRef = useRef<HTMLImageElement>(null);

  ////////////////////////////////////////////////
  // UseEffect
  ////////////////////////////////////////////////

  useEffect(() => {
    console.log("2222");

    if (props.images) {
      setPropsImgs(props.images);
    } else {
      setPropsImgs([]);
      setCropStates([]);
    }

    if (props.aspectRatio) {
      setAspect(props.aspectRatio);
    }

    if (props.resetHandler) {
      props.resetHandler(resetCropState);
    }

  }, [props.images]);

  ////////////////////////////////////////////////
  // Utility Functions
  ////////////////////////////////////////////////

  const [cropStates, setCropStates] = React.useState<CropState[]>(
    props.images?.map(() => ({
      crop: undefined,
      completedCrop: undefined,
      scale: 1,
      rotate: 0,
    })) || []
  );

  const resetCropState = () => {
    setImgSrcs([]);
    setPropsImgs([]);
    setCropStates([]);
    previewCanvasRefs.current = [];
    imgRefs.current = [];
    props.setImages([]);
  };

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files;
      // setCrop(undefined);
      const readers = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setImgSrcs((prev) => [...prev, reader.result?.toString() || ""])
        );
        reader.readAsDataURL(file);
        return reader;
      });
      console.log(readers);
    }
  };

  // !
  const onImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement>,
    index: number
  ) => {
    console.log("on image load");

    const { width, height } = e.currentTarget;
    console.log("width, height");
    console.log(width, height);
    // setCrop(centerAspectCrop(width, height, aspect));

    const newCrop = centerAspectCrop(width, height, aspect);

    handleCropChange(index, newCrop);
  };

  const handleCropChange = (index: number, newCrop: any) => {
    const updatedCropStates = [...cropStates];
    updatedCropStates[index] = {
      ...updatedCropStates[index],
      crop: newCrop,
    };
    setCropStates(updatedCropStates);
  };

  const handleCropComplete = (index: number, completedCrop: any) => {
    const updatedCropStates = [...cropStates];
    updatedCropStates[index] = {
      ...updatedCropStates[index],
      completedCrop,
    };
    setCropStates(updatedCropStates);
  };

  const handleScaleChange = (index: number, newScale: number) => {
    const updatedCropStates = [...cropStates];
    updatedCropStates[index] = {
      ...updatedCropStates[index],
      scale: newScale,
    };
    setCropStates(updatedCropStates);
  };

  const handleRotateChange = (index: number, newRotate: number) => {
    const updatedCropStates = [...cropStates];
    updatedCropStates[index] = {
      ...updatedCropStates[index],
      rotate: newRotate,
    };
    setCropStates(updatedCropStates);
  };

  const handleAddPropsImage = (index: number, blob: any) => {
    const updatedImages = [...(props.images as any[])];
    updatedImages[index] = blob;
    // props.setImages((prevImages: any) => [...prevImages, blob]);
    props.setImages(updatedImages);
  };

  const createBlob = async (img: HTMLImageElement, index: number) => {
    if (
      cropStates[index]?.completedCrop?.width &&
      cropStates[index]?.completedCrop?.height &&
      imgRefs.current[index] &&
      previewCanvasRefs.current[index]
    ) {
      await canvasPreview(
        imgRefs.current[index]!,
        previewCanvasRefs.current[index]!,
        cropStates[index].completedCrop!,
        cropStates[index].scale,
        cropStates[index].rotate
      );

      if (previewCanvasRefs.current[index]) {
        previewCanvasRefs.current[index]?.toBlob((blob) => {
          if (blob) {
            // props.setImages((prev: any) => [...prev, blob]);
            handleAddPropsImage(index, blob);
          }
        });
      }
    }
  };

  // useDebounceEffect to delay function execution by certain amount of time
  useDebounceEffect(
    async () => {
      imgSrcs.forEach((imgSrc, index) => {
        const imgElement = new Image();
        imgElement.src = imgSrc;
        imgElement.onload = () => createBlob(imgElement, index);
      });
    },
    100,
    [cropStates]
  );

  ////////////////////////////////////////////////
  // JSX
  ////////////////////////////////////////////////

  return (
    <div className="App">
      {/* Show title and controls */}
      <div className="Crop-Controls">
        <p>{props.title}</p>
        <input type="file" accept="image/*" onChange={onSelectFiles} multiple />
      </div>

      {/* Show the croppable images */}
      {imgSrcs.map((src, index) => {
        const { crop, completedCrop, scale, rotate } = cropStates[index] || {};
        previewCanvasRefs.current[index] =
          previewCanvasRefs.current[index] || null;
        return (
          <div key={index}>
            {src && (
              <ReactCrop
                crop={crop || undefined}
                onChange={(_, percentCrop) =>
                  handleCropChange(index, percentCrop)
                }
                onComplete={(c) => handleCropComplete(index, c)}
                aspect={aspect}
              >
                <img
                  height={300}
                  ref={(element) => (imgRefs.current[index] = element!)}
                  alt="Crop me"
                  src={src}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={(element) => {
                    onImageLoad(element, index);
                  }}
                />
              </ReactCrop>
            )}
            {/* {!!completedCrop && !propsImgs[index] && ( */}

            {/* If crop is completed, no propsImages, show the cropped image */}
            {completedCrop && completedCrop.width && completedCrop.height && (
              // !propsImgs[index] &&
              <>
                <div>
                  <canvas
                    ref={(element) =>
                      (previewCanvasRefs.current[index] = element)
                    }
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </>
            )}

            {/* If prop image at that index, show cropped */}
            {propsImgs[index] && (
              <img
                height={300}
                alt="Cropped"
                src={propsImgs[index]}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                onLoad={(element) => {
                  onImageLoad(element, index);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleImageUpload;
