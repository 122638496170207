import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import PageLoader from "../PageLoader";
import { log } from "console";
import { PAGE_SIZE } from "../../Shared/globalVar";

interface TableListProps {
  columns: GridColDef[];
  isLoading: boolean;
  rows: any[];
  paginationMode?: boolean;
  rowsCount?: number;
  onRowClick?: (...args: any[]) => void;
  getItems?: (...args: any[]) => void;
  dataGridApiRef?: React.MutableRefObject<any>;
  showRowNumbers?: boolean; 
  showRowNumbersNonPaginated?: boolean;
  rowNumbersDescending?: boolean;

}

const TableList: React.FunctionComponent<TableListProps> = (props) => {
  const { dataGridApiRef } = props;

  const [pageIndex, setPageIndex] = useState(0);

  const renderRowNumber = (pageIndex: number, pageSize: number, index: number, descending: boolean = false) => {
    
    return descending
    ? (props.rowsCount || 0) - (pageIndex * pageSize + index)
    : pageIndex * pageSize + index + 1 
    // : (props.rows.length || 0) - (pageIndex * pageSize + index);
  };
  
  const renderRowNumberNonPaginated = (index: number, descending: boolean = false) => {
    return descending
    ? props.rows.length - index
    : index + 1
  
  };

  let rows;

  if (props.rowNumbersDescending && props.showRowNumbersNonPaginated) {
    rows = props.rows.reverse();
  } else {
    rows = props.rows;
  }

  let processedRows = rows.map((row, index) => {
    let rowNumber;
    if (props.showRowNumbers) {
      rowNumber = renderRowNumber(
        pageIndex,
        PAGE_SIZE,
        index,
        props.rowNumbersDescending !== undefined ? true : false
      );
    } else if (props.showRowNumbersNonPaginated) {
      rowNumber = renderRowNumberNonPaginated(
        index,
        props.rowNumbersDescending !== undefined ? true : false
      );
    }

    return {
      ...row,
      rowNumber: rowNumber,
    };
  });  

  let columns: GridColDef[] = props.columns;

  if (props.showRowNumbers || props.showRowNumbersNonPaginated) {
    const rowNumberColumnNonPaginated: GridColDef = {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      renderCell: (params) => params.row.rowNumber,
    };
    columns = [rowNumberColumnNonPaginated, ...columns];
  }

  return (
    <div>
      <PageLoader loading={props.isLoading} />

      <Box sx={{ height: "70%", width: "100%" }}>
        <DataGrid
          // key={props && props.rowsCount == 0 ? "a" : "b"}
          onRowClick={(params, event) => {
            if (props.onRowClick) {
              props.onRowClick(params);
            }
          }}
          rows={processedRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: PAGE_SIZE,
                page: pageIndex,
              },
            },
          }}
          pageSizeOptions={[10]}
          paginationMode={
            props.paginationMode && props.paginationMode === true
              ? "server"
              : "client"
          }
         
          apiRef={props.dataGridApiRef}
          disableRowSelectionOnClick
          rowCount={props.rowsCount}
          onPaginationModelChange={(x) => {
            console.log(`Current page: ${x.page}`);
            if (props.getItems && props.rowsCount) {
              if (props.rows.length < props.rowsCount) {
                if ((x.page + 1) * x.pageSize >= props.rows.length) {
                  props.getItems(x.page + 1);
                }
              }
            }
          }}
        />
      </Box>
    </div>
  );
};

export default TableList;

