import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import TableList from "../../Components/Table/Table";
import AddUpdateHomeSection from "../../Components/AddUpdateHomeSection/AddUpdateHomeSection";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IHomeSection } from "../../Models/IHomeSection";
import { MenuItem, Select } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { useDeleteHandler } from "../../Hooks/useDeleteHandler";

interface HomeSectionListProps {}

const HomeSectionList: React.FunctionComponent<HomeSectionListProps> = () => {
  const [HomeSections, setHomeSections] = useState<[]>([]);
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const getHomeSectionsClient = useHttpClient();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const [selectedHomeSection, setSelectedHomeSection] = useState<IHomeSection | null>();

  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/homesection/delete",
    requestMethod: "post",
    onSuccess: async () => {
      setHomeSections([]);
      await getHomeSections();
    },
  });

  useEffect(() => {
    if (openAddUpdateModal === false) {
      setSelectedHomeSection(null);
    }
  }, [openAddUpdateModal, setOpenAddUpdateModal]);
  
  useEffect(() => {
    getHomeSections();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "icon",
      headerName: "icon",
      width: 90,
      renderCell: (params) =>
        params.row.icon && (
          <img
            width={120}
            height={90}
            src={params.row.icon}
            alt="icon"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.icon);
            }}
          />
        ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 150,
      editable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 80,
      editable: true,
    },
    {
      field: "autoFill",
      headerName: "Autofill",
      width: 70,
      editable: true,
    },
    {
      field: "subType",
      headerName: "Subtype",
      width: 150,
      editable: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedHomeSection(params.row);
            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isActive}
          onChange={async (e) => {
            console.log(
              `Updating active status. New status: ${e.target.value}`
            );
            await updateActiveStatus(params.row.id, e.target.value === "true");
            setHomeSections([]);
            await getHomeSections();
          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
  ];

  const getHomeSections = async () => {
    try {
      const response = await getHomeSectionsClient.sendRequest({
        url: "/owner/homesection",
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        setHomeSections(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getHomeSectionsClient.error);
    }
  };

  const updateActiveStatus = async (id: any, isActive: any) => {

    try {
      const response = await getHomeSectionsClient.sendRequest({
        url: "/owner/homesection/isactive",
        method: "put",
        body: { id, isActive, sectionType: "home" },
        // body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

    } catch (e) {
      console.log(e);
      console.log(getHomeSectionsClient.error);
    }
  };


  return (
    <div className="page">
      <SnackbarErrorClient client={getHomeSectionsClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <AddUpdateHomeSection
        open={openAddUpdateModal}
        homeSection={selectedHomeSection}
        setOpen={setOpenAddUpdateModal}
        getHomeSections={getHomeSections}
      />

      <div className="pageUpper">
        <p className="pageTitle">Home Sections</p>

        <CustomButton
          text="Add Home Section"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </div>

      {/* <SearchBar fetchDataById={fetchHomeSectionById} rows={HomeSections} /> */}

      <TableList
        rows={HomeSections}
        isLoading={getHomeSectionsClient.isLoading}
        columns={columns}
      />
    </div>
  );
};

export default HomeSectionList;
