import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import SingleImageUpload from "../SingleImageUpload/SingleImageUpload";
import imageCompression from "browser-image-compression";
import { IVendor } from "../../Models/IVendor";
import { ITouristSection } from "../../Models/ITouristSection";
import { IDiscount } from "../../Models/IDiscount";
import IconUploadSelector from "../IconUploadSelector.tsx/IconUploadSelector";
import useSingleImageUploadManager from "../../Hooks/useSingleImageUploadManager";

interface AddUpdateTouristSectionProps {
  touristSection?: ITouristSection | null;

  open: any;
  setOpen: any;
  getTouristSections: any;
}

const AddUpdateTouristSection: React.FunctionComponent<
  AddUpdateTouristSectionProps
> = (props) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);

  const {
    image,
    imageSrc,
    initImageSrc,
    zoom,
    uploadNewImage,
    setImage,
    setImageSrc,
    setInitImageSrc,
    setZoom,
    toggleUploadNewImage,
    handleImageUpload,
    handleIconUploadChange
  } = useSingleImageUploadManager();

  const submitTouristSectionClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    defValues = {};
    reset(defValues);

  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();

      submitTouristSectionClient.setLoading(true);

      if (imageSrc != null) {
        console.log("IMMMM SOURCE");
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        console.log("IMMMM SOURCE");

        formData.append("image", compressedFile);
      }
      console.log("Image: ");
      console.log(image);

      submitTouristSectionClient.setLoading(false);

      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);
      formData.append("category", values.category);

      // If editing an existing ad section
      if (props.touristSection) {
        formData.append("id", props.touristSection.id ?? "");

        console.log("FORMDATAAAA", formData.get("images"));

        const response = await submitTouristSectionClient.sendRequest({
          url: "/owner/homesection/touristic/item",
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });

        // If Creating a new ad section
      } else {
        const response = await submitTouristSectionClient.sendRequest({
          url: "/owner/homesection/touristic/item",
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }

      props.setOpen(false);
      props.getTouristSections();
    } catch (e) {
      console.log(e);
      submitTouristSectionClient.setLoading(false);
    }
    console.log(values);
  };

  return (
    <div>
      <SnackbarErrorClient client={submitTouristSectionClient} />
      <PageLoader loading={submitTouristSectionClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.touristSection ? "Edit" : "Create"}{" "}
            {props.touristSection?.name ?? ""} Tourist Section
          </p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.touristSection?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.touristSection?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.touristSection?.category}
              name="category"
              label={"Category ID"}
            />
            {/* <input type="file" name="file" title="Main I" onChange={handleFileChange}></input> */}

            <IconUploadSelector
              uploadNewImage={uploadNewImage}
              handleIconUploadChange={handleIconUploadChange}
            />

            {/* Show existing image */}

            {/* Show message if image url exists */}
            {!uploadNewImage &&
              (props.touristSection?.image ? (
                <p>Existing image found.</p>
              ) : (
                <p>No existing image found</p>
              ))}

            {!uploadNewImage && (
              <img width={90} height={90} src={props.touristSection?.image} />
            )}

            {/* Upload new image */}
            {uploadNewImage && (
              <SingleImageUpload
                title={"Main Image"}
                img={initImageSrc}
                setImg={setImageSrc}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton
              text={props.touristSection ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateTouristSection;
