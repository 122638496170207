import { useState, useCallback } from "react";
import { useHttpClient } from "./HttpHook";


interface useDeleteHandlerProps {
    requestUrl: string;
    requestMethod: string;
    onSuccess?: () => void;
    onError?: (error: any) => void;
  }

export const useDeleteHandler = ( props: useDeleteHandlerProps ) => {
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [idOfItemToDelete, setIdOfItemToDelete] = useState<any>(null);
    const deleteClient = useHttpClient();

    const handleDeleteClick = (id: any) => {
        setIdOfItemToDelete(id);
        setOpenDeletePopup(true);
    };


    const deleteItem = async () => {
        try {

            if (!idOfItemToDelete) return;

            console.log(props.requestUrl)
            console.log(props.requestMethod)
            
            const response = await deleteClient.sendRequest({
                url: props.requestUrl,
                method: props.requestMethod,
                body: { id: idOfItemToDelete },
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log(response);

            setOpenDeletePopup(false);

            if (props.onSuccess) {
                props.onSuccess();
            }

        } catch (error) {
            console.log(`Error deleting item: ${error}`);
            
            if (props.onError) {
                props.onError(error);
            }
        }
    };

    return {
        openDeletePopup,
        setOpenDeletePopup,
        handleDeleteClick,
        deleteItem,
        deleteClient
    };
};
