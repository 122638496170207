import { useState, useEffect, useCallback } from "react";
import imageCompression from "browser-image-compression";

const useSingleImageUploadManager = () => {
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);
  const [zoom, setZoom] = useState(1);
  const [uploadNewImage, toggleUploadNewImage] = useState<boolean>(true);

  const handleIconUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedValue = event.target.value === "true" ? true : false;
    toggleUploadNewImage(updatedValue);
  };

  const handleImageUpload = useCallback(async (file: File) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        setImage({ preview: reader.result as string, data: compressedFile.name });
        setImageSrc(compressedFile);
      };
    } catch (error) {
      console.error("Error during image compression:", error);
    }
  }, []);

  useEffect(() => {
    setImage({ preview: "", data: "" });
    setImageSrc(null);
    setInitImageSrc(null);
  }, []);

  return {
    image,
    imageSrc,
    initImageSrc,
    zoom,
    uploadNewImage,
    setImage,
    setImageSrc,
    setInitImageSrc,
    setZoom,
    toggleUploadNewImage,
    handleImageUpload,
    handleIconUploadChange,
  };
};

export default useSingleImageUploadManager;
