import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import {
  Alert,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useHttpClient } from "../../Hooks/HttpHook";
import { ICustomer } from "../../Models/ICustomer";
import { ILocation } from "../../Models/ILocation";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import CustomerList from "../../Pages/CustomerList/CustomerList";
import PageLoader from "../PageLoader";
import PopupBase from "../PopupBase/PopupBase";

interface GenericPopupProps {
  data: { [key: string]: any };
  open: any;
  setOpen: any;
}

const GenericPopup: React.FunctionComponent<GenericPopupProps> = (props) => {
  const httpClient = useHttpClient();

  return (
    <PopupBase open={props.open} setOpen={props.setOpen}>
      <PageLoader loading={httpClient.isLoading} />
          <Table>
            <TableBody>
              {Object.entries(props.data).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value?.toString() ?? ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </PopupBase>
  );
};

export default GenericPopup;
