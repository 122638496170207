import { useGridApiRef } from "@mui/x-data-grid";
import { useState } from "react";
import { useHttpClient } from "./HttpHook";

export const usePaginationManager = () => {

    const [items, setItems] = useState<any[]>([]);
    const dataGridApiRef = useGridApiRef();
    const [count, setCount] = useState(0);
    const httpClient = useHttpClient();
    const [status, setStatus] = useState(0);

    return {
        items,
        setItems,
        dataGridApiRef,
        count,
        setCount,
        status,
        setStatus,
        httpClient
    };
}