import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";

interface UpdateABSZoneProps {
  cityId?: any | null;
  type?: string | null;
  open: any;
  setOpen: any;
  onSubmit?: any | null;
}

const UpdateABSZone: React.FunctionComponent<UpdateABSZoneProps> = (props) => {
  const [error, setError] = useState("");
  const [zones, setZones] = useState<{label: string, value: string}[]>([
 
  ]);

  useEffect(() => {

    if (props.type === "abs") {
      setZones([
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
      ]);
    }else{
      setZones([
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
      ])
    }
  }, [props]);

  const handleClose = () => props.setOpen(false);

  const submitCategoryClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    // if (props.product) {
    //   console.log(props.product);
    defValues = {};

    console.log(props.cityId);

    reset(defValues);
    // } else {
    // }
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const onSubmit = async () => {
    const values = control._formValues;

    const zoneId = control._formValues.zone.value;

    try {
      await submitCategoryClient.sendRequest({
        url:
          props.type == "abs"
            ? "/owner/city/abs/city"
            : "/owner/city/aramex/city",
        method: "post",
        body: {
          cityId: props.cityId,
          zoneId: zoneId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log("we here");
      props.setOpen(false);
      console.log(props.open);

      props.onSubmit();
    } catch (e) {}
    console.log(values);
  };

  return (
    <div>
      <SnackbarErrorClient client={submitCategoryClient} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">Update City Zone</p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <PageLoader loading={submitCategoryClient.isLoading} />

            <FormInputText
              rules={{}}
              inputType="autocomplete"
              options={zones}
              control={control}
              name="zone"
              label={"Zone"}
            />

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton text={"Create"} onClick={handleSubmit(onSubmit)} />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateABSZone;
