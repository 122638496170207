import * as XLSX from 'xlsx';

class ProductExcelImportHelper {

    static async getJSONFromExcelFile(): Promise<any[]> {
        try {
            const file = await this.selectExcelFile();
            const data = await this.readExcelFile(file);
            return this.processExcelDataToJSON(data);
        } catch (error) {
            console.log('Error processing file:', error);
            throw error;
        }
    }
    
    private static async selectExcelFile(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.xlsx, .xls';
    
            input.onchange = () => {
                const target = input as HTMLInputElement;
                const file = target.files?.[0];
                if (file) {
                    resolve(file);
                } else {
                    reject(new Error('No file selected'));
                }
            };
    
            input.click();
        });
    }
    
    private static async readExcelFile(file: File): Promise<Uint8Array> {
        return new Promise<Uint8Array>((resolve, reject) => {
            const reader = new FileReader();
            
            reader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(new Uint8Array(e.target?.result as ArrayBuffer));
            };
            
            reader.onerror = (error) => {
                reject(new Error('Failed to read file: ' + error));
            };
            
            reader.readAsArrayBuffer(file);
        });
    }
    
    private static processExcelDataToJSON(data: Uint8Array): any[] {
        const workbook = XLSX.read(data, { type: 'array' });
        const allSheetData: any[] = [];
    
        workbook.SheetNames.forEach(sheetName => {
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as (string | number)[][];
    
            if (sheetData.length === 0) {
                console.log(`No data found in sheet: ${sheetName}`);
                return;
            }
    
            const headers = sheetData[0] as string[];
            const dataObjects = sheetData.slice(1).map((row: (string | number)[]) => {
                const obj: Record<string, string | number> = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });
    
            allSheetData.push({ sheetName, data: dataObjects });
        });
    
        // console.log("Transformed Data from all sheets:", allSheetData);
        return allSheetData;
    }
    
    
}


export default ProductExcelImportHelper;