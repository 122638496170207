import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IProduct } from "../../Models/IProduct";
import FormInputText from "../FormInputText/FormInputText";
import { useForm } from "react-hook-form";
import "./ReviewProduct.scss";
import CustomButton from "../CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import PageLoader from "../PageLoader";

interface ReviewOrEditProductProps {
  product?: IProduct | null;
  isEditing?: boolean;
  toggleIsEditing?: any;
  open: any;
  setOpen: any;
  getProducts: any;
}

const ReviewOrEditProduct: React.FunctionComponent<ReviewOrEditProductProps> = (
  props
) => {
  // const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => {
    props.setOpen(false);

    if (props.isEditing && props.toggleIsEditing) {
      props.toggleIsEditing();
    }
  };

  const reviewProductClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    if (props.product) {
      console.log(props.product);
      defValues = {
        id: props.product.id ?? "",
        name: props.product ? props.product.name ?? "" : "",
        price: props.product ? props.product.price ?? null : null,
        description: props.product ? props.product.description ?? "" : "",
        categoryName: props.product ? props.product.categoryName ?? "" : "",
        categoryId: props.product ? props.product.category ?? "" : "",
        gender: props.product ? props.product.gender ?? "" : "",
        forKids: props.product ? props.product.forKids ?? "false" : "false",
        sku: props.product ? props.product.sku ?? "" : "",
        reason: "",
      };

      reset(defValues);
    } else {
    }
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const reviewProduct = async (id: any, decision: any) => {
    setError("");
    const reason = control._formValues.reason;

    console.log("REASON" + reason + "REASON");

    if (decision === "reject" && (!reason || reason === " ")) {
      setError("Reason required");

      console.log("reson stuff");

      return;
    }

    try {
      const response = await reviewProductClient.sendRequest({
        url: "/owner/product/review",
        method: "post",
        body: {
          id,
          decision,
          reason,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getProducts(true, true);
    } catch (e) {
      console.log(reviewProductClient.error);
    }
  };

  const editProduct = async (product: IProduct | null) => {
    const values = control._formValues;

    try {
      const values = control._formValues;

      let formData = new FormData();

      if (values.id) {
        formData.append("id", values.id);
      }

      if (values.name) {
        formData.append("name", values.name);
      }

      if (values.price) {
        formData.append("price", values.price);
      }

      if (values.description) {
        formData.append("description", values.description);
      }

      // if (values.gender) {
      //   formData.append("gender", values.gender);
      // }

      if (
        values.gender?.value === "male" ||
        values.gender?.value === "female"
      ) {
        formData.append("gender", values.gender?.value);
      }

      if (values.forKids?.value) {
        formData.append("forKids", values.forKids?.value);
      }

      if (values.sku) {
        formData.append("sku", values.sku);
      }

      if (values.categoryId) {
        formData.append("categoryId", values.categoryId);
      }

      console.log(`ReviewOrEditProduct - editProduct - outputting formData`);
      formData.forEach((value, key) => {
        console.log(`\t${key}: ${value}`);
      });

      console.log("ReviewOrEditProduct - values");
      console.log(values);

      console.log(`values.forKids.value: ${values.forKids.value}`);
      console.log(`values.forKids: ${values.forKids}`);

      const response = await reviewProductClient.sendRequest({
        url: "/owner/product/edit",
        method: "post",
        body: {
          id: values.id,
          name: values.name,
          price: values.price,
          description: values.description,
          // gender: values.gender,
          // forKids: values.forKids.value ?? values.forKids,
          sku: values.sku,
          categoryId: values.categoryId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getProducts(true, true);
    } catch (e) {
      console.log(reviewProductClient.error);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={reviewProductClient} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.isEditing ? "Edit" : "Review"} Product
          </p>

          {props.product && (
            <form className="reviewProduct">
              <PageLoader loading={reviewProductClient.isLoading} />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="id"
                label={"Id"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="name"
                label={"Name"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="price"
                type="number"
                label={"price"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="description"
                label={"description"}
                multiline={true}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="categoryName"
                label={"Category Name"}
              />
              <FormInputText
                rules={{}}
                // disabled={props.isEditing ? false : true}
                disabled={true}
                control={control}
                // inputType="autocomplete"
                // options={[
                  // { label: "All", value: "" },
                  // { label: "Male", value: "male" },
                  // { label: "Female", value: "female" },
                // ]}
                name="gender"
                label={"gender"}
                value={props.product?.gender}
              />

              <FormInputText
                rules={{}}
                // disabled={props.isEditing ? false : true}
                disabled={true}
                // inputType="autocomplete"
                // options={[
                  // { label: "True", value: "true" },
                  // { label: "False", value: "false" },
                // ]}
                control={control}
                name="forKids"
                label={"forKids"}
                value={props.product?.forKids}
              />

              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="sku"
                label={"sku"}
              />

              {props.isEditing && (
                <FormInputText
                  rules={{}}
                  control={control}
                  name="categoryId"
                  label={"Category ID"}
                />
              )}

              {!props.isEditing && (
                <FormInputText
                  rules={{}}
                  control={control}
                  name="reason"
                  label={"reason"}
                />
              )}

              {error && <Alert severity="error">{error}</Alert>}

              {props.product &&
                props.product.images &&
                props.product.images.length > 0 && (
                  <div className="container">
                    {props.product.images.map((object, i) => (
                      <img className="productImage" src={object.toString()} />
                    ))}
                  </div>
                )}

              {!props.isEditing && (
                <CustomButton
                  text={"Accept"}
                  onClick={() => reviewProduct(props.product?.id, "accept")}
                />
              )}

              {!props.isEditing && (
                <CustomButton
                  text={"Reject"}
                  isSecondary={true}
                  onClick={() => reviewProduct(props.product?.id, "reject")}
                />
              )}

              {props.isEditing && (
                <CustomButton
                  text={"Edit"}
                  onClick={() => editProduct(props.product ?? null)}
                />
              )}
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewOrEditProduct;
