import { useState } from "react";
import { useDeleteHandler } from "./useDeleteHandler";
import { useHttpClient } from "./HttpHook";

interface useSearchBarProps {
  getByIdRequestUrl: string;
  getByIdRequestMethod: string;
  getByNameRequestUrl?: string;
  getByNameRequestMethod?: string;
  responseField?: string;
  queryStrings?: string[],
  bodyField?: string;

  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const useSearchBar = (props: useSearchBarProps) => {
  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState<any[]>([]);

  const getSearchBarItemById = async (id: string) => {
    try {
      
      const bodyField = props.bodyField ?? "id";
      const bodyObj = { [bodyField]: id };

      const response = await searchBarClient.sendRequest({
        url: `${props.getByIdRequestUrl}?searchBarId=${id}`,
        method: props.getByIdRequestMethod,
        body: bodyObj,
      });

      if (response) {

        console.log(`useSearchBar - getById - response`);
        console.log(response);

        if (props.responseField && response[props.responseField]) {
            setSearchBarResults(response[props.responseField]);
        }

        else if (props.responseField && !response[props.responseField]) {
            console.log(`SearchBarResults - responseField not found in response`);
            throw new Error("Error fetching search bar results.");
        }

        else {
            setSearchBarResults(response);
        }


      }
    } catch (error) {
      console.log(`useSearchBar - error`);
      console.log(error);
    }
  };

  const getSearchBarItemByName = async (name: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: `${props.getByNameRequestUrl}?searchBarName=${name}`,
        method: props.getByNameRequestMethod,
        body: {
          name: name,
        },
      });

      if (response) {

        console.log(`useSearchBar - getByName - response`);
        console.log(response);

        if (props.responseField && response[props.responseField]) {
            setSearchBarResults(response[props.responseField]);
        }

        else if (props.responseField && !response[props.responseField]) {
            console.log(`SearchBarResults - responseField not found in response`);
            throw new Error("Error fetching search bar results.");
        }

        else {
            setSearchBarResults(response);
        }


      }
    } catch (error) {
      console.log(`useSearchBar - error`);
      console.log(error);
    }
  };

  return {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById,
    getSearchBarItemByName
  };
};

export default useSearchBar;
